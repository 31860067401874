///////////////// GLOBAL SCOPE //////////////////
import * as Focus from '../../../assets/scripts/common/focus';
require('./mods/stylesheet-control.js');
require('./mods/font-size-control.js');
// eslint-disable-next-line no-undef
const vtxAccessibility = VTX_ACCESSIBILITY || {};
/**
 * Ajouter une méthode à l'object vtxAccessibility créé avec wp_localize_script (voir inc/accessibility.init.php)
 */
export function extendVtxAccessibility() {
  vtxAccessibility.toggleRelatedInputState = function toggleRelatedInputState(
    $input
  ) {
    const accessibilityType = $input.data('accessibility-type');
    const accessibilityTypeValue = $input.data(accessibilityType);
    const $relatedEl = $(
      '[data-' + accessibilityType + "='" + accessibilityTypeValue + "']"
    ).not('#' + $input.attr('id'));

    if ($relatedEl.is("[type='checkbox']")) {
      // Toggle checked state
      const isChecked = !!$relatedEl.prop('checked');
      $relatedEl.prop('checked', !isChecked);
    } else if ($relatedEl.is("[type='radio']")) {
      $relatedEl.prop('checked', true);
    }
  };
}
// eslint-disable-next-line jsdoc/check-line-alignment
/**
 *
 * @param {Object} $toggle
 * @param {string} state May be "closed" or "opened"
 */

export function changeToggleState($toggle, state) {
  let dataLabelName = '';

  // CLOSED
  if ('closed' === state) {
    dataLabelName = 'label-open';
    $toggle.removeClass('opened');
    $toggle.attr('aria-expanded', 'false');
  }
  // OPENED
  else if ('opened' === state) {
    dataLabelName = 'label-close';
    $toggle.addClass('opened');
    $toggle.attr('aria-expanded', 'true');
  }

  const label = $toggle.data(dataLabelName);
  if (label) {
    $toggle.find('.accessibility-menu__toggle__label').html(label);
  }
}

export function closeAccessibilityMenu(e, giveFocusBackToToggle) {
  /* jshint ignore:start */
  $(document)
    .off('click', tryClosingAccessibilityMenuOnClick)
    .off('keydown', tryClosingAccessibilityMenuOnKeydown);
  /* jshint ignore:end */

  if (giveFocusBackToToggle) {
    $('.accessibility-menu__toggle.opened').focus();
  }

  const $menu = $('.accessibility-menu.opened');

  Focus.untrapFocusInside($menu);

  changeToggleState($('.accessibility-menu__toggle.opened'), 'closed');

  $menu.removeClass('opened');
}

export function tryClosingAccessibilityMenuOnKeydown(e) {
  if (27 === e.keyCode) {
    // When ESCAPE is pressed
    closeAccessibilityMenu(e, true);
  }
}

export function tryClosingAccessibilityMenuOnClick(e) {
  if (
    $(e.target).hasClass('accessibility-menu__close') ||
    !$('.accessibility-menu.opened').find(e.target).length
  ) {
    closeAccessibilityMenu(e, true);
  }
}

export function openAccessibilityMenu(e) {
  e.preventDefault();

  const $currentAccessibilityMenu = $($(e.target).data('menu-target'));

  if (!$currentAccessibilityMenu.hasClass('opened')) {
    $currentAccessibilityMenu.addClass('opened');
    changeToggleState($(e.target), 'opened');
    Focus.trapFocusInside($currentAccessibilityMenu);

    // Donner un petit délais pour que le CSS change le visibility:hidden à visible.
    // Le délais va donner le temps au Screen Reader de ce rendre compte que l'élément existe.
    setTimeout(function () {
      /* jshint ignore:start */
      initCloseAccessibilityEvents();
      /* jshint ignore:end */
      const $first = $currentAccessibilityMenu.find('input').first();
      if ($first.is('[type="radio"]')) {
        $('[name="' + $first.attr('name') + '"]')
          .filter(':checked')
          .focus();
      } else {
        $first.focus();
      }
    }, 100);
  }
}

export function toggleAccessibilityMenu(e) {
  e.preventDefault();

  const $currentAccessibilityMenu = $($(e.target).data('menu-target'));
  if (!$currentAccessibilityMenu.hasClass('opened')) {
    openAccessibilityMenu(e);
  } else {
    closeAccessibilityMenu(e);
  }
}

export function initToggleAccessibilityMenu() {
  $('.accessibility-menu__toggle').on('click', toggleAccessibilityMenu);
}

export function initCloseAccessibilityEvents() {
  $(document)
    .on('click', tryClosingAccessibilityMenuOnClick)
    .on('keydown', tryClosingAccessibilityMenuOnKeydown);
}

/////////////// ON READY /////////////////
$(function () {
  extendVtxAccessibility();
  initToggleAccessibilityMenu();
}); // end ready
